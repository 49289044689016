@import './node_modules/@ingka/variables/style.scss';

.react-datepicker-popper {
  position: unset !important;
  will-change: unset !important;
  top: unset !important;
  left: unset !important;
  transform: unset !important;
}
.igift-datepicker {
  justify-content: center;

  .react-datepicker__header {
    border: none;
    border-radius: 0;
    background-color: $colour-neutral-1;

    .react-datepicker__day-name {
      height: $space-250;
      width: $space-250;
      line-height: $space-250;
      vertical-align: middle;
      font-weight: $font-weight-bold;
      font-size: $font-size-100;
    }
  }
}
.react-datepicker__day {
  &.igift-calendar-day {
    border-radius: $radius-cap;
    color: $colour-neutral-6;
    height: $space-250;
    width: $space-250;
    line-height: $space-250;
    vertical-align: middle;
    font-size: $font-size-100;
    &.react-datepicker__day--disabled {
      text-decoration-line: line-through;
      color: $colour-neutral-5;
      cursor: not-allowed;
    }

    &.react-datepicker__day--selected {
      background-color: $colour-static-ikea-brand-blue;
      color: $colour-neutral-1;
    }
    &.react-datepicker__day--keyboard-selected {
      background-color: $colour-neutral-2;
    }
  }
}
.igift-datepicker-input-wrapper {
  .react-datepicker__input-container {
    z-index: 10;
  }
}

.igift-datepicker.inline {
  position: absolute;
  z-index: 999;
  background: $colour-neutral-1;
  height: 24.375rem;
}
