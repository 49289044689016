@import '../skapa/styles.scss';

@font-face {
  font-family: 'Noto IKEA';
  font-style: normal;
  font-weight: normal;
  src: local('Noto IKEA'),
    url('./fonts/notosansikea-regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Noto IKEA';
  font-style: normal;
  font-weight: 700;
  src: local('Noto IKEA'),
    url('./fonts/notosansikea-bold.ttf') format('truetype');
}

body {
  margin: 0;
  background: $colour-neutral-1;
  color: $colour-neutral-6;
  min-height: 100%;
}

* {
  font-family: $font-family-latin;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none;
}
